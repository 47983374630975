<script setup>
import "vue3-carousel/dist/carousel.css";
// import { Carousel, Slide } from "vue3-carousel";
// import IconArrowCicleRight from "@/components/icons/ArrowCircleRight";
// import IconArrowCicleLeft from "@/components/icons/ArrowCircleLeft";

// const props = reactive({
//   imgSlider: [
//     {
//       id: 1,
//       name: "Sesa.id",
//       logo: "/images/sesa-logo.png",
//       review:
//         "“FLIK provide solutions and insight based on our business problem. Their technology is here to help us achieve our business goal. They listen to your problem”",
//       reviewer: "Reni Rahmawati, E-Commerce Manager",
//       img: "/images/sesa.jpg",
//     },
//     {
//       id: 2,
//       name: "ZMNow.id & Geraihawa.co.id",
//       logo: "/images/zmnowxgeraihawa.png",
//       review:
//         "“When using FLIK, we experience a lot of convenience in running our business. The shipping process, payments, as well as the return/refund process are well-organized.”",
//       reviewer:
//         "Fiqih Wardana, Sales Executive & Brand Executive Zaskia Mecca Group",
//       img: "/images/zmnow.jpg",
//     },
//   ],
// });

// const carousel = ref(null);
// function prev() {
//   carousel.value.prev();
// }

// function next() {
//   carousel.value.next();
// }
</script>
<template>
  <div class="background-auth flex min-h-screen bg-[#F6FAFF]">
    <Notif />
    <div class="mx-auto w-full p-8 px-0 md:max-w-[480px] md:p-10 md:px-8">
      <div class="mx-auto mb-10 flex w-full justify-center">
        <img
          src="/images/flik-logo-black.svg"
          alt="Flik Logo"
          class="h-6 object-contain"
        />
      </div>
      <div
        class="flex h-full flex-1 flex-col bg-white p-6 md:h-auto md:justify-center md:rounded-2xl"
      >
        <slot />
      </div>
    </div>
  </div>
</template>

<style scoped>
.background-auth {
  background: url("/images/background.svg");
  background-size: cover;
  background-attachment: fixed;
}
</style>
